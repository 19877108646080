import logo from './logo.svg';
import './App.css';
import { Routes, Route, Form } from 'react-router-dom';

import FormPage from './Form';

import Navigate from './Navigate';
function App() {
  return (
    <>
    <Routes>

 <Route path='/'element={<FormPage/>}/>
 
 <Route path='/navigate'element={<Navigate/>}/>
 </Routes>
    </>
  
  );
}

export default App;
