import React from 'react'
import { Grid, Typography,useMediaQuery } from '@mui/material';

const Navigate = () => {
    const mobile = useMediaQuery('(max-width:600px)');
  return (
    <>
   
   <Grid container lg={12} xs={12} sx={{ margin: 'auto', backgroundColor: '#f0f0f0', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Grid item xs={12} lg={4}>
        <Typography sx={{ textAlign: 'center', color: '#103648', fontSize:mobile?'20px': '38px' }}>
          Your form has been successfully submitted!
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: mobile?'14px':'20px' }}>
          Thank you for your submission.
        </Typography>
      </Grid>
    </Grid>
    </>
  )
}

export default Navigate