import React, { useState } from 'react';
import axios from 'axios';
import { Api_url } from './helper';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const FormPage = () => {
  const navigate=useNavigate();
    const [formData, setFormData] = useState({
        workshopName: '',
        date: '',
        time: '',
        earlyBirdFee: '',
        regularFee: '',
        agenda: {
          heading1: '',
          
          subheading1: '',
          headinga:'',
          subheading2: '',
          heading2: '',
          headinga:'',
          subheading3: '',
          subheading4: '',
          heading3: '',
          
          subheading5: '',
          headinga:'',
          subheading6: '',
          heading4: '',
          headinga:'',
          subheading7: '',
          subheading8: '',
          heading5: '',
          headinga:'',
          subheading9: '',
          subheading10: '',
          heading6: '',
          headinga:'',
          subheading11: '',
          subheading12: '',
        },
        keyPoints: {
            heading1: '',
          
            subheading1: '',
            headinga:'',
            subheading2: '',
            heading2: '',
            headinga:'',
            subheading3: '',
            subheading4: '',
            heading3: '',
            
            subheading5: '',
            headinga:'',
            subheading6: '',
            heading4: '',
            headinga:'',
            subheading7: '',
            subheading8: '',
        },
        aboutTheTrainer: {
            heading1: '',
          
            subheading1: '',
            headinga:'',
            subheading2: '',
            subheading3: '',
        },
        workshopoverview: {
            heading1: '',
            subheading1: '',
            heading2: '',
            subheading2: '',  
            heading3: '',
            subheading3: '',
            heading4: '',
            subheading4: '',
          },
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
      
        if (name.includes('.')) {
          const [section, heading, subheading] = name.split('.');
          setFormData((prevData) => ({
            ...prevData,
            [section]: {
              ...prevData[section],
              [heading]: {
                ...prevData[section][heading],
                [subheading]: value,
              },
            },
          }));
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };
      const handleFileChange = (e, fieldName) => {
		const file = e.target.files[0];
		setFormData((prevData) => ({
			...prevData,
			[fieldName]: file,
		}));
	};
	

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    console.log(formData)
	  try {
		const formDataToSend = new FormData();

        // Append form data
        Object.entries(formData).forEach(([key, value]) => {
            if (value instanceof File) {
                formDataToSend.append(key, value);
            } else if (typeof value === 'object') {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    if (subValue instanceof Object) {
                        Object.entries(subValue).forEach(([nestedKey, nestedValue]) => {
                            formDataToSend.append(`${key}.${subKey}.${nestedKey}`, nestedValue);
                        });
                    } else {
                        formDataToSend.append(`${key}.${subKey}`, subValue);
                    }
                });
            } else {
                formDataToSend.append(key, value);
            }
		});
		  console.log(formDataToSend,'formada')
      await axios.post(`${Api_url}/api/submitForm`,  formDataToSend, {
		headers: {
			'Content-Type': 'multipart/form-data', // Make sure to set content type to multipart/form-data
		},
	});
      console.log('Form submitted successfully');
      navigate('/navigate')
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <Grid container lg={12}>
        <Grid container lg={11} sx={{margin:'auto'}}>
          <Grid item lg={12} sx={{textAlign:'center'}}>
          <Typography sx={{fontSize:'24px',fontWeight:'600',marginTop:'50px'}}>Workshop Form</Typography>
          </Grid>
       
      <form onSubmit={handleSubmit}>
        
        <Typography  sx={{ fontSize:'18px',fontWeight:'600', marginBottom: '20px',marginTop:'20px' }}>
          Workshop Details
        </Typography>
        <label>
          <Grid item lg={10}>
            <Typography>Name of Workshop:</Typography>
          </Grid>
          
          <input type="text" name="workshopName" value={formData.workshopName} onChange={handleChange} />
        </label>
        <br />

        <label>
        <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>Date:</Typography>
          </Grid>
          
          <input type="date" name="date" value={formData.date} onChange={handleChange} />
        </label>
        <br />

        <label>
        <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Time:</Typography>
          </Grid>
         
          <input type="time" name="time" value={formData.time} onChange={handleChange} />
        </label>
        <br />

        <label>
        <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>  Early Bird Fee:</Typography>
          </Grid>
         
          <input type="text" name="earlyBirdFee" value={formData.earlyBirdFee} onChange={handleChange} />
        </label>
        <br />

        <label>
        <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>  Regular Fee:</Typography>
          </Grid>
          
          <input type="text" name="regularFee" value={formData.regularFee} onChange={handleChange} />
        </label>
        <br />

        <label>
        <Grid item lg={12} sx={{textAlign:'center'}}>
          <Typography sx={{fontSize:'24px',fontWeight:'600',marginTop:'50px'}}>  Agenda:</Typography>
          </Grid>
        
          <br/>
          <Grid item lg={10} >
          <Typography sx={{fontSize:'18px',fontWeight:'600',marginTop:'50px'}}>  Point 1:</Typography>
          </Grid>
           
          <br />
          <label>
          <Grid item lg={10} >
          <Typography >  Heading 1:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading1.headinga" value={formData.agenda.heading1.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>   Subheading 1:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading1.subheading1" value={formData.agenda.heading1.subheading1} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>   Subheading 2:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading1.subheading2" value={formData.agenda.heading1.subheading2} onChange={handleChange} />
          </label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',fontSize:'18px',fontWeight:'600',}}>   Point 2:</Typography>
          </Grid>
          
          <br />
          
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'-20px'}}> Heading 2:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading2.headinga" value={formData.agenda.heading2.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}>  Subheading 3:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading2.subheading3" value={formData.agenda.heading2.subheading3} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Subheading 4:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading2.subheading4" value={formData.agenda.heading2.subheading4} onChange={handleChange} />
          </label>
          <br />
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',fontSize:'18px',fontWeight:'600'}}>  Point 3:</Typography>
          </Grid>
         
          <br />
          <label>
          <Grid item lg={10}>
            <Typography > Heading 3:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading3.headinga" value={formData.agenda.heading3.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Subheading 5:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading3.subheading5" value={formData.agenda.heading3.subheading5} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Subheading 6:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading3.subheading6" value={formData.agenda.heading3.subheading6} onChange={handleChange} />
          </label>
          <br />
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',fontSize:'18px',fontWeight:'600'}}>  Point 4:</Typography>
          </Grid>
         
          <br />
          <label>
          <Grid item lg={10}>
            <Typography >  Heading 4:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading4.headinga" value={formData.agenda.heading4.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Subheading 7:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading4.subheading7" value={formData.agenda.heading4.subheading7} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px'}}> Subheading 8:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading4.subheading8" value={formData.agenda.heading4.subheading8} onChange={handleChange} />
          </label>
          <br />
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',fontSize:'18px',fontWeight:'600'}}> Point 5:</Typography>
          </Grid>
          
          <br />
          <label>
          <Grid item lg={10}>
            <Typography > Heading 5:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading5.headinga" value={formData.agenda.heading5.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',}}> Subheading 9:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading5.subheading9" value={formData.agenda.heading5.subheading9} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',}}> Subheading 10:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading5.subheading10" value={formData.agenda.heading5.subheading10} onChange={handleChange} />
          </label>
          <br />
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',fontSize:'18px',fontWeight:'600'}}> Point 6:</Typography>
          </Grid>
          
          <br />
          <label>
          <Grid item lg={10}>
            <Typography >  Heading 6:</Typography>
          </Grid>
            
            <input type="text" name="agenda.heading6.headinga" value={formData.agenda.heading6.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',}}>  Subheading 11:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading6.subheading11" value={formData.agenda.heading6.subheading11} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography sx={{marginTop:'10px',}}>   Subheading 12:</Typography>
          </Grid>
           
            <input type="text" name="agenda.heading6.subheading12" value={formData.agenda.heading6.subheading12} onChange={handleChange} />
          </label>

          {/* ... Repeat the above structure for other headings and subheadings */}
        </label>
        <br />
        <br />

       
        <br />

       
        <br />

        <label>
        <Grid item lg={12} sx={{textAlign:'center'}}>
          <Typography sx={{fontSize:'24px',fontWeight:'600',marginTop:'50px'}}>   Workshop keyPoints:</Typography>
          </Grid>
         
          <br />
          <label>
          <Grid item lg={10}>
            <Typography >  Heading 1:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading1.headinga"
              value={formData.keyPoints.heading1.headinga}
              onChange={handleChange}
            />
          </label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 1:</Typography>
          </Grid>
          <br />
          <label>
            
            <input
              type="text"
              name="keyPoints.heading1.subheading1"
              value={formData.keyPoints.heading1.subheading1}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>   Subheading 2:</Typography>
          </Grid>
           
            <input
              type="text"
              name="keyPoints.heading1.subheading2"
              value={formData.keyPoints.heading1.subheading2}
              onChange={handleChange}
            />
          </label>
          <br />

          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>   Heading 2:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading2.headinga"
              value={formData.keyPoints.heading2.headinga}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 3:</Typography>
          </Grid>
           
            <input
              type="text"
              name="keyPoints.heading2.subheading3"
              value={formData.keyPoints.heading2.subheading3}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 4:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading2.subheading4"
              value={formData.keyPoints.heading2.subheading4}
              onChange={handleChange}
            />
          </label>
          <br />

          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Heading 3:</Typography>
          </Grid>
           
            <input
              type="text"
              name="keyPoints.heading3.headinga"
              value={formData.keyPoints.heading3.headinga}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 5:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading3.subheading5"
              value={formData.keyPoints.heading3.subheading5}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 6:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading3.subheading6"
              value={formData.keyPoints.heading3.subheading6}
              onChange={handleChange}
            />
          </label>
          <br />

          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Heading 4:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading4.headinga"
              value={formData.keyPoints.heading4.headinga}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 7:</Typography>
          </Grid>
           
            <input
              type="text"
              name="keyPoints.heading4.subheading7"
              value={formData.keyPoints.heading4.subheading7}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 8:</Typography>
          </Grid>
            
            <input
              type="text"
              name="keyPoints.heading4.subheading8"
              value={formData.keyPoints.heading4.subheading8}
              onChange={handleChange}
            />
          </label>
        </label>
        <br />
        <label>
        <Grid item lg={12} sx={{textAlign:'center'}}>
          <Typography sx={{fontSize:'24px',fontWeight:'600',marginTop:'50px'}}>About the Trainer:</Typography>
          </Grid>
          
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Heading 1:</Typography>
          </Grid>
            
            <input type="text" name="aboutTheTrainer.heading1.headinga" value={formData.aboutTheTrainer.heading1.headinga} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 1:</Typography>
          </Grid>
            
            <input type="text" name="aboutTheTrainer.subheading1.subheading1" value={formData.aboutTheTrainer.subheading1.subheading1} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 2:</Typography>
          </Grid>
            
            <input type="text" name="aboutTheTrainer.subheading2.subheading2" value={formData.aboutTheTrainer.subheading2.subheading2} onChange={handleChange} />
          </label>
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 3:</Typography>
          </Grid>
           
            <input type="text" name="aboutTheTrainer.subheading3.subheading3" value={formData.aboutTheTrainer.subheading3.subheading3} onChange={handleChange} />
          </label>
          {/* Repeat the above structure for other headings and subheadings */}
        </label>
        <br />
        <label>
        <Grid item lg={12} sx={{textAlign:'center'}}>
          <Typography sx={{fontSize:'24px',fontWeight:'600',marginTop:'50px'}}> Workshop Overview</Typography>
          </Grid>
         
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Heading 1:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.heading1.heading1" value={formData.workshopoverview.heading1.heading1} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>   Subheading 1:</Typography>
          </Grid>
           
            <input type="text" name="workshopoverview.subheading1.subheading1" value={formData.workshopoverview.subheading1.subheading1} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>   Heading 2:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.heading2.heading2" value={formData.workshopoverview.heading2.heading2} onChange={handleChange} />
          </label>
          <br />
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 2:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.subheading2.subheading2" value={formData.workshopoverview.subheading2.subheading2} onChange={handleChange} />
          </label>
          <br />
          <label>
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Heading 3:</Typography>
          </Grid>
           
            <input type="text" name="workshopoverview.heading3.heading3" value={formData.workshopoverview.heading3.heading3} onChange={handleChange} />
          </label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Subheading 3:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.subheading3.subheading3" value={formData.workshopoverview.subheading3.subheading3} onChange={handleChange} />
          </label>
          <label>
          <label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}>  Heading 4:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.heading4.heading4" value={formData.workshopoverview.heading4.heading4} onChange={handleChange} />
          </label>
          <Grid item lg={10}>
            <Typography  sx={{marginTop:'10px'}}> Subheading 4:</Typography>
          </Grid>
            
            <input type="text" name="workshopoverview.subheading4.subheading4" value={formData.workshopoverview.subheading4.subheading4} onChange={handleChange} />
          </label>
          {/* Repeat the above structure for other headings and subheadings */}
					  </label>
					  <h1>Desktop backgroundImage</h1>
					  <input type="file" onChange={(e) => handleFileChange(e, 'photo1')} />
					  <h2>Mobile backgroundImage</h2>
					  <input type="file" onChange={(e) => handleFileChange(e, 'photo2')} />
					  <h3>About The Trainer Image</h3>
<input type="file" onChange={(e) => handleFileChange(e, 'photo3')} />

        <button type="submit" style={{backgroundColor:'cyan'}}>Submit</button>
      </form>
      </Grid>
      </Grid> 
    </>
  );
};

export default FormPage;